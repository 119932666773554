import './App.css';
import {
  useAuthUser,
  useAuthUserOrNull,
  useIsAuthenticated,
} from '@frontegg/react';
import Banner from './components/Banner/Banner';

import { config } from './config/config';
import NavBar from './components/NavBar/NavBar';
import { FunctionComponent, useEffect } from 'react';
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import DashboardPage from './pages/Dashboard/DashboardPage';
import ProfilePage from './pages/Profile/ProfilePage';
import AccountPage from './pages/Account/AccountPage';
import MeetingPage from './pages/Meeting/MeetingPage';
import AdminPage from './pages/Admin/AdminPage';
import ScheduleMeetingPage from './pages/Meeting/ScheduleMeetingPage';
import RescheduleMeetingPage from './pages/Meeting/RescheduleMeetingPage';
import NewSubscriptionPage from './pages/Subscriptions/NewSubscriptionPage';
import SubscriptionConfirmationPage from './pages/Subscriptions/SubscriptionConfirmationPage';
import SubscriptionBanner from './components/Banner/SubscriptionBanner';
import { JourneyPage } from './pages/Journey/JourneyPage';
import ThemePage from './pages/Theme/ThemePage';
import GuidePage from './pages/Guide/GuidePage';

const ProtectedRoute: FunctionComponent = () => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/account/login') {
      localStorage.setItem('AUTH_LOCATION', location.pathname);
    }
  }, [isAuthenticated, location.pathname]);

  useAuthUser();

  return (
    <>
      <NavBar />
      <SubscriptionBanner />
      <Outlet />
    </>
  );
};

function App() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectLocation = localStorage.getItem('AUTH_LOCATION');

    if (isAuthenticated && redirectLocation) {
      localStorage.removeItem('AUTH_LOCATION');
      navigate(redirectLocation);
    }
  }, [isAuthenticated, navigate]);

  const user = useAuthUserOrNull();

  useEffect(() => {
    if (user && user.accessToken) {
      localStorage.setItem('AUTH_TOKEN', user.accessToken);
    }
  }, [user]);

  return (
    <div className="App">
      <header className="header">
        {config.env === 'production' ? null : (
          <Banner
            backgroundColor="#fff8c5"
            message={`Currently viewing the ${config.env} environment.`}
          />
        )}
      </header>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/profile/*" element={<ProfilePage />} />
          <Route path="/account/*" element={<AccountPage />} />
          <Route path="/admin/*" element={<AdminPage />} />
          <Route path="/journeys/*" element={<JourneyPage />} />
          <Route path="/themes/:slug" element={<ThemePage />} />
          <Route path="/guides/:slug" element={<GuidePage />} />
          <Route path="/*" element={<DashboardPage />} />
          <Route
            path="/meeting/:id/schedule"
            element={<ScheduleMeetingPage />}
          />
          <Route
            path="/meeting/:id/reschedule"
            element={<RescheduleMeetingPage />}
          />
        </Route>
        <Route path="/meeting/:id/*" element={<MeetingPage />} />
        <Route path="/subscription/" element={<NewSubscriptionPage />} />
        <Route
          path="/subscription/confirmation"
          element={<SubscriptionConfirmationPage />}
        />
      </Routes>
    </div>
  );
}

export default App;
